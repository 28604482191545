import * as React from "react"
import HeardTopWrapper from "../components/header-wrapper"
import Layout from "../components/layout/index"
import PageContainer from "../components/page-container"
import {Accordion} from "react-bootstrap"
import { Helmet } from 'react-helmet';
import { Link } from "gatsby"
import {useTranslation} from 'gatsby-plugin-react-i18next';
import{graphql} from "gatsby"
const Features = () => {
  const {t} = useTranslation();
  return(
    <>
      <HeardTopWrapper title={t('FrequentlyAskedQuestions')}/>
      <Layout>
        <Helmet>
            <title>{t('myKitchenInspector')}｜{t('FAQ')}</title>
        </Helmet>
        <PageContainer>
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header><span>{t('FaqQuestion1')}</span></Accordion.Header>
              <Accordion.Body>
              {t('FaqAnswer1Text1')} <Link to="../startfreetrial">{t('FaqAnswer1Text2')}</Link>{t('FaqAnswer1Text3')}
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header><span>{t('FaqQuestion2')}</span></Accordion.Header>
              <Accordion.Body>
              {t('FaqAnswer2')}
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header><span>{t('FaqQuestion3')}</span></Accordion.Header>
              <Accordion.Body>
              {t('FaqAnswer3')}
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header><span>{t('FaqQuestion4')}</span></Accordion.Header>
              <Accordion.Body>
              {t('FaqAnswer4Text1')} <Link to="../shop">{t('FaqAnswer4Text2')}</Link>. 
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
              <Accordion.Header><span>{t('FaqQuestion5')}</span></Accordion.Header>
              <Accordion.Body>
                {t('FaqAnswer5')}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </PageContainer>
      </Layout>
    </>
  )
}


export default Features
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
